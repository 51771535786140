
import { defineComponent } from 'vue'
import { useRouter } from 'vue-router'

export default defineComponent({
    name: 'PaySuccess',
    setup () {
        // const router = useRouter()
        // const handlerToOrderList = () => {
        //     router.push('/order/list')
        // }
        return {
            // handlerToOrderList
        }
    }
})

